import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { actions, selectors } from '../../modules';
import {
  BASE_ASSET_ID,
  AUTH_TOKEN,
  ATTACH_DIS,
  HOLDING,
  POST_MESSAGE_TYPE,
} from '../../constants';
import { PlayerContainer } from './Player.styled';
import { fetchMetadata } from '../../modules/utils/app';
import {
  findAvailableHole,
  findClosestHole,
  highlightAvailableHoles,
  cancelHighlightAvailableHoles,
  highlightOverlay,
  highlightInstrument,
  getOccipiedHole,
  onAnnotationChange,
  setInstrumentPosition,
  hitBlock,
  hitInstrument,
  getInstrumentMovingPlane,
} from '../../modules/utils/config';

class Player extends PureComponent {
  constructor(props) {
    super(props);
    // this.mouseDown = false;
    // this.activeTool = null;
    // this.mouseDownModel = null;
    this.playerRef = React.createRef();
    this.toolState = {
      firstHitModel: undefined,
      highlightHole: false,
      swapModel: undefined,
      swappedModel: undefined,
      primaryTool: 'orbit',
      attachHole: {},
      attachedHole: {},
      dragged: false,
    };
    this.movingOptions = {};
  }
  async componentDidMount() {
    const { setInitState, preFetchBlocks, postMessageToParent } = this.props;

    const metadata = await fetchMetadata();
    const threekitApi = await window.threekitPlayer({
      authToken: AUTH_TOKEN,
      assetId: BASE_ASSET_ID,
      el: this.playerRef.current,
      onAnnotationChange,
    });

    await setInitState({
      app: { loaded: true },
      threekit: { threekitApi: threekitApi },
      metadata: metadata,
    });
    preFetchBlocks();
    window.addEventListener('message', this.messageHandler);

    const rayIntersect = new threekitApi.THREE.Vector3();
    threekitApi.tools.addTool({
      key: 'brasselerSelect',
      label: 'Brasseler Select',
      active: true,
      enabled: true,
      handlers: {
        click: async (ev) => {
          const {
            selectedModel,
            setSelectedModel,
            lidAnimation,
            animateLid,
            instruments,
          } = this.props;
          const { playing } = lidAnimation;
          if (playing) return;
          if (hitBlock(ev.hitNodes, instruments)) animateLid();

          if (this.toolState.firstHitModel !== selectedModel)
            setSelectedModel(this.toolState.firstHitModel);
        },
        mousedown: (ev) => {
          const { instruments } = this.props;

          const hits = ev.hitNodes;

          const firstHitInstrument = hitInstrument(hits[0], instruments);
          const hitModel = firstHitInstrument && firstHitInstrument.modelId;
          this.initToolState(hitModel);
          this.initMovingOptions(hitModel);
          hitModel && highlightInstrument(threekitApi, hitModel);
          threekitApi.tools.setPrimary(this.toolState.primaryTool);
        },
        mouseup: (ev) => {
          this.toolState.highlightHole &&
            cancelHighlightAvailableHoles(
              threekitApi,
              this.movingOptions.availableHoles
            );
          this.toolState.highlightHole = false;
        },
      },
    });
    threekitApi.tools.addTool({
      key: 'brasselerNodeMove',
      label: 'Brasseler Node Move',
      modifiers: { drag: [[2, 'altKey', 'ctrlKey', 'metaKey', 'shiftKey']] }, // this modifiers will make sure the nodeMove is not likely be called unless set to primary
      active: true,
      enabled: true,
      handlers: {
        drag: () => ({
          handle: async (ev) => {
            this.toolState.dragged = true;
            const { getOverlayModelId, block, instruments } = this.props;
            const { movingBoundary, blockData } = block;
            const { instrumentHeightOffset } = blockData;
            const instrumentPositionOption = { offset: instrumentHeightOffset };
            const movingPlane = getInstrumentMovingPlane(
              block,
              instruments[this.toolState.firstHitModel]
            );

            if (!this.toolState.highlightHole) {
              highlightAvailableHoles(
                threekitApi,
                this.movingOptions.availableHoles
              );
              this.toolState.highlightHole = true;
            }

            ev.eventRay.ray.intersectPlane(movingPlane, rayIntersect);
            const closestHole = findClosestHole(
              rayIntersect,
              this.movingOptions.availableHoles
            );

            const attach = closestHole.distance < ATTACH_DIS;

            if (
              !attach &&
              (Math.abs(rayIntersect.x) > movingBoundary.x ||
                Math.abs(rayIntersect.z) > movingBoundary.z)
            )
              return;

            let movingTarget;

            if (attach) {
              if (
                this.toolState.attachHole.idx !== closestHole.idx ||
                this.toolState.attachHole.shank !== closestHole.shank
              ) {
                this.toolState.attachedHole = this.toolState.attachHole;
                this.toolState.attachHole = closestHole;
                this.toolState.swapModel =
                  this.movingOptions.occupiedHole[closestHole.shank][
                    closestHole.idx
                  ];
                movingTarget = closestHole;
              } else return;
            } else {
              if (this.toolState.attachHole.idx) {
                // from attach to deattach

                this.toolState.attachedHole = this.toolState.attachHole;
                this.toolState.attachHole = {};
              }
              const overlayModelId = await getOverlayModelId(
                this.toolState.firstHitModel,
                rayIntersect
              );
              highlightOverlay(threekitApi, overlayModelId);
              movingTarget = { translation: rayIntersect };
            }

            if (this.toolState.swappedModel) {
              // restore the preview swappedModel, if exist, to its original position
              const swappedInstrument =
                instruments[this.toolState.swappedModel];
              const { holeShank, holeIdx } = swappedInstrument;
              setInstrumentPosition(
                threekitApi,
                swappedInstrument,
                this.movingOptions.holeData[holeShank][holeIdx],
                instrumentPositionOption
              );
            }

            if (this.toolState.swapModel) {
              // set the current swapModel, if exist, to the initial hole of the dragging instrument
              const swapInstrument = instruments[this.toolState.swapModel];
              setInstrumentPosition(
                threekitApi,
                swapInstrument,
                this.movingOptions.initialHole,
                instrumentPositionOption
              );
            }

            if (movingTarget) {
              const moveInstrument = instruments[this.toolState.firstHitModel];
              setInstrumentPosition(
                threekitApi,
                moveInstrument,
                movingTarget,
                instrumentPositionOption
              );
              this.toolState.attachedHole = {};
            }
            this.toolState.swappedModel = this.toolState.swapModel;
            this.toolState.swapModel = undefined;
          },
          onEnd: () => {
            const {
              instruments,
              setInstruments,
              selectedModel,
              block,
            } = this.props;
            if (!this.toolState.dragged) return;
            const moveInstrument = instruments[this.toolState.firstHitModel];
            const {
              idx: newHoleIdx,
              shank: newHoleShank,
            } = this.toolState.attachHole;
            const { blockData } = block;
            highlightInstrument(threekitApi, selectedModel);
            if (!newHoleIdx)
              return setInstrumentPosition(
                threekitApi,
                moveInstrument,
                this.movingOptions.initialHole,
                { offset: blockData.instrumentHeightOffset }
              );

            const newInstrumentData = [
              {
                modelId: this.toolState.firstHitModel,
                holeIdx: String(newHoleIdx),
                holeShank: newHoleShank,
              },
            ];
            if (this.movingOptions.occupiedHole[newHoleShank][newHoleIdx]) {
              newInstrumentData.push({
                modelId: this.movingOptions.occupiedHole[newHoleShank][
                  newHoleIdx
                ],
                holeIdx: this.movingOptions.initialHole.idx,
                holeShank: this.movingOptions.initialHole.shank,
              });
              this.toolState.swappedModel = undefined;
            }

            setInstruments(newInstrumentData);
          },
        }),
      },
    });
    // ..............................................................................

    // this is for testing
    window.threekitApi = threekitApi;
    this.intervalId = setInterval(() => {
      postMessageToParent(POST_MESSAGE_TYPE.keepLive);
    }, 600000);
    //.......................
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.messageHandler);
  }

  initToolState(hitModel) {
    const { instruments } = this.props;

    this.toolState.primaryTool = hitModel ? 'brasselerNodeMove' : 'orbit';
    this.toolState.dragged = false;
    this.toolState.firstHitModel = hitModel;
    this.toolState.swappedModel = undefined;
    this.toolState.swapModel = undefined;
    this.toolState.attachHole = instruments[hitModel]
      ? {
          idx: instruments[hitModel].holeIdx,
          shank: instruments[hitModel].holeShank,
        }
      : {};
    this.toolState.attachedHole = {};
  }

  initMovingOptions(hitModel) {
    if (!hitModel) return;

    const { instruments, block, holding } = this.props;
    const { holeData } = block;
    const { holeShank, holeIdx } = instruments[hitModel];
    const allHoleData = { ...holeData, [HOLDING.shankName]: holding };

    const filteredInstruments = { ...instruments };
    delete filteredInstruments[hitModel];

    this.movingOptions = {
      availableHoles: findAvailableHole(
        block,
        filteredInstruments,
        instruments[hitModel],
        { allowSwap: true, holding }
      ),
      occupiedHole: getOccipiedHole(holeData, filteredInstruments, holding),
      holeData: allHoleData,
      initialHole: allHoleData[holeShank][holeIdx],
    };
  }

  messageHandler = (ev) => {
    if (!window || window.origin === ev.origin) return;
    this.props.parentMessageHandler(ev);
  };

  render() {
    return (
      <PlayerContainer>
        <div ref={this.playerRef} />
      </PlayerContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  env: state.app.env,
  selectedModel: state.config.selectedModel,
  instruments: state.config.instruments,
  block: state.config.block,
  holding: state.config.holding,
  lidAnimation: state.config.lidAnimation,
});
const mapDispatchToProps = {
  setInitState: actions.setInitState,
  setSelectedModel: actions.config.setSelectedModel,
  setInstruments: actions.config.setInstruments,
  animateLid: actions.config.animateLid,
  parentMessageHandler: actions.order.parentMessageHandler,
  getOverlayModelId: selectors.config.getOverlayModelId,
  preFetchBlocks: actions.config.preFetchBlocks,
  postMessageToParent: actions.app.postMessageToParent,
};
export default connect(mapStateToProps, mapDispatchToProps)(Player);
