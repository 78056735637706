const env = process.env.REACT_APP_ENV;

// threekit platform const
const platformApiUrl = 'https://admin.threekit.com';
export const AUTH_TOKEN =
  env === 'PROD'
    ? 'b53afde5-5dbc-45a3-86fe-5e384fb5b979'
    : '423b9e78-8579-473b-817d-56862be9162c';

export const PRODUCT_SHEET_ID = '1DLNl3QGxriVofQfi_C65A_1lMy6nSmCCbojlv_g1uhM'; //Staging Master Sheet
export const LOGIC_SHEET_ID = '18gStpFBdLwwnlubVqX6YA88s5_fEUpvJHb5tQhKc7us';

export const PROD_ORG_ID = 'a8cb3325-5e3b-4552-8114-a0e9e70e08c8';
export const DEV_ORG_ID = '0eca932d-abee-40e3-9991-342e9337aa72';
export const ORG_ID = env === 'PROD' ? PROD_ORG_ID : DEV_ORG_ID;

export const PLATFORM_UPLOAD_URL = `${platformApiUrl}/api/catalog/assets/upload?bearer_token=${AUTH_TOKEN}`;
export const PLATFORM_UPLOAD_JOB_API = `${platformApiUrl}/api/catalog/jobs/`;
export const PLATFORM_APP_API = `${platformApiUrl}/api/apps`;
export const PLATFORM_ASSET_API = `${platformApiUrl}/api/assets`;
export const PLATFORM_FILE_API = `${platformApiUrl}/api/files`;

export const APP_URL =
  env === 'PROD'
    ? 'https://brasseler.mythreekit.com'
    : env === 'DEV'
    ? 'https://brasseler.mythreekit.dev'
    : 'http://localhost:5002';
export const APP_LOGO_API = `${APP_URL}/api/logo/modify`;
export const APP_PRESET_API = `${APP_URL}/api/preset`;
export const APP_METADATA_API = `${APP_URL}/api/spreadsheet`;
export const APP_ASSET_API = `${APP_URL}/api/asset`;
export const APP_THREEKIT_API = `${APP_URL}/api/threekit`;
// asset const
export const BASE_ASSET_ID = '9ed4d13d-b06a-4dce-94b8-47d5655d4c08';
const holdingAssetId = 'e0b3c120-2556-4474-990b-40123f925550';
export const ROLLBAR_TOKEN = 'cb00ae6cc9044885a669018fb67c8e46';
export const BLOCK_ROOT = 'Block Root';
export const LID_ROOT = 'Lid_Root';
export const MM_TO_M = 0.001;
export const ATTACH_DIS = 0.03;
export const HOLE_PLANE_ASSET_ID = '589c1559-3072-4505-89cd-47b6846c8314';

export const DECAL_INSTRUMENT_PLANE = 'Decal_Instrument_Plane';
export const INSTRUMENT_PLACEHOLDER_ASSET =
  '620ca795-8483-4577-99fe-98bcff193b82';
export const INSTRUMENT_SHADOW_MAP = {
  FG: 'Small',
  RA: 'Large',
};

export const HOLDING = {
  assetId: holdingAssetId,
  root: 'Holding_Area',
  shankName: 'Holding',
};

export const LID_ANIMATION = {
  angle: -60,
  open: 'open',
  close: 'close',
  duration: 500,
  step: 10,
};

//  UI Constants
export const UI_FLOW = {
  procedure: 'procedure',
  blank: 'blank',
  view: 'view',
};
export const UI_SECTIONS = {
  procedure: ['procedure', 'block', 'customize', 'summary'],
  blank: ['block', 'instruments', 'customize', 'summary'],
};

export const SEARCH = {
  instruments: ['productNumber', 'productLabel', 'description'],
};

export const POST_MESSAGE_TYPE = {
  productListChange: 'productListChange',
  getConfiguration: 'getConfiguration',
  addToCart: 'addToCart',
  keepLive: 'keepLive',
};

export const RECEIVE_MESSAGE_TYPE = {
  updatePrice: 'updatePrice',
  getConfiguration: 'getConfiguration',
  addToCart: 'addToCart',
};

export const COUNTRY_DATA = {
  ca: {
    currencyFormat: 'fr-CA',
    currency: 'CAD',
    stagingShareLink: 'https://stageshop.brasseler.ca',
    prodShareLink: 'https://shop.brasseler.ca',
  },
  us: {
    currencyFormat: 'en-US',
    currency: 'USD',
    stagingShareLink: 'https://brasseler.commerce.insitesandbox.com',
    prodShareLink: 'https://shop.brasselerusa.com',
  },
  usa: {
    currencyFormat: 'en-US',
    currency: 'USD',
    stagingShareLink: 'https://brasseler.commerce.insitesandbox.com',
    prodShareLink: 'https://shop.brasselerusa.com',
  },
};

export const CONFIG_COUNTRY_MAPPING = {
  us: 'USA',
  ca: 'CA',
  usa: 'USA',
};
